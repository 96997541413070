import {Box} from 'native-base';
import CompetitionSearchList from './search/CompetitionSearchList';
import React from 'react';
import {WEB_LG_MAX_WIDTH} from '@ui/helpers/constants';

const CompetitionsExplore = () => {
    return (
        <Box flex={1} pt='4' alignItems="center">
            <Box w={{base: 'full', lg: WEB_LG_MAX_WIDTH}}flex={1} h='full'>
                <CompetitionSearchList />
            </Box>
        </Box>
    );
};

export default CompetitionsExplore;
