import * as React from 'react';
import {SceneMap, TabView} from 'react-native-tab-view';
import {Box} from 'native-base';
import CompetitionSearchList from './search/CompetitionSearchList';
import CompetitionsMyTournaments from '@ui/features/competition/CompetitionsMyTournaments';
import WAMPTabBar from '@ui/components/WAMPTabBar';
import {WEB_LG_MAX_WIDTH} from '@ui/helpers/constants';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';
import {useWindowDimensions} from 'react-native';

const ExploreCompetitions = () => (
    <Box flex={1} pt='4' alignItems="center">
        <Box w={{base: 'full', lg: WEB_LG_MAX_WIDTH}}flex={1} h='full'>
            <CompetitionSearchList />
        </Box>
    </Box>
);

const MyTournaments = () => (
    <CompetitionsMyTournaments />
);

const renderScene = SceneMap({
    'explore': ExploreCompetitions,
    'myTournaments': MyTournaments,
});

const CompetitionsTabScreen = () => {
    const {user} = useUser();
    const layout = useWindowDimensions();
    const {t} = useTranslation();

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        {key: 'explore', title: t('competition.list.exploreTournaments.title')},
        {key: 'myTournaments', title: t('competition.list.myTournaments.title')},
    ]);

    if(!user) {
        return <></>;
    }

    return (
        <TabView
            navigationState={{index, routes}}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{width: layout.width}}
            renderTabBar={(props) => <WAMPTabBar tabProps={props} index={index} asHeader />}
            lazy
        />
    );
};

export default React.memo(CompetitionsTabScreen);
