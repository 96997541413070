import * as React from 'react';
import {IBoxProps, Row, useBreakpointValue, useTheme} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import {StyleSheet} from 'react-native';
import {TabBar} from 'react-native-tab-view';
import {TopBarWrapper} from '@ui/layout/TopBarWrapper';

const styles = StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    tabBarStyle: {
        backgroundColor: 'white',
    },
    tabBarLabelStyle: {
        textTransform: 'capitalize',
        fontFamily: 'Inter-Medium',
        fontSize: 14,
        lineHeight: 24,
    }},
);

interface WAMPTabBarProps extends IHStackProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabProps: any;
    asHeader?: boolean;
    index: number;
    _center?: IBoxProps;
    tabBarStyle?: any;
    ExtraTabViews?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WAMPTabBar = (props: WAMPTabBarProps) => {
    const {tabProps, asHeader = false, tabBarStyle, ExtraTabViews, ...restProps} = props;

    const theme = useTheme();
    const width = useBreakpointValue({
        base: '100%',
        lg: '300px',
    });

    return (
        <Row w='100%' bg='white' borderBottomWidth={1} borderBottomColor='secondary.0' {...restProps}>
            <TopBarWrapper fullWidth={!asHeader} _center={props._center}>
                <Row w='100%' justifyContent='space-between'>
                    <TabBar
                        {...tabProps}
                        indicatorStyle={{backgroundColor: theme.colors.black}}
                        labelStyle={[styles.tabBarLabelStyle, {
                            color: theme.colors.black,
                        }]}
                        style={[styles.tabBarStyle, {width}, tabBarStyle]}
                        inactiveColor={theme.colors.secondary[300]}
                    />

                    {ExtraTabViews}
                </Row>
            </TopBarWrapper>
        </Row>
    );
};

export default WAMPTabBar;
