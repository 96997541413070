import CompetitionsExplore from '@ui/features/competition/CompetitionsExplore';
import CompetitionsTabScreen from '@ui/features/competition/CompetitionsTabScreen';
import DashboardLayout from '@ui/layout/DashboardLayout';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const CompetitionsScreen = () => {
    const {t} = useTranslation();
    const {isLoggedIn} = useUser();

    return (
        <DashboardLayout title={t('competition.list.title')} header={{selectedTab: 'competitions', showBorder: !isLoggedIn}} useMainContentContainer={false} extra={{qrTop: isLoggedIn ? 50 : 0}}>
            {isLoggedIn
                ? <CompetitionsTabScreen />
                : <CompetitionsExplore />
            }
        </DashboardLayout>
    );
};

export default CompetitionsScreen;
