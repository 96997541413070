import {Box, Text} from 'native-base';
import React, {useMemo} from 'react';
import CompetitionsList from '@ui/features/competition/CompetitionsList';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import {WEB_LG_MAX_WIDTH} from '@ui/helpers/constants';
import {compareDesc} from 'date-fns';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';
import {isChallengeCourse} from '@shared/helpers/competition-fn';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const CompetitionsMyTournaments = () => {
    const {user, isLoading} = useUser();
    const {t} = useTranslation();

    const competitions = Object.values(user?.competitions ?? {});

    const sorted = useMemo(() => {
        return competitions
            .filter(c => c !== undefined)
            .filter(c => !isChallengeCourse(c))
            .sort((a, b) => {
                if (a && b) {
                    return compareDesc(getDatabaseDate(a.startsAt), getDatabaseDate(b.startsAt));
                } else {
                    return 0;
                }
            })
            .map(item => {
                return {
                    item,
                    userJoined: true,
                };
            });
    }, [competitions]);

    if (!user) {
        return null;
    }

    if (!user.competitions || !user.competitionIds || user.competitionIds.length === 0) {
        return (
            <Box
                px={6}
                flex={1}
                justifyContent='center'
                alignItems='center'
            >
                <Text
                    fontSize={{
                        base: 20,
                        lg: 32,
                    }}
                    textAlign='center'
                    maxW={600}
                >
                    {t('competition.list.myTournaments.empty')}
                </Text>
            </Box>
        );
    }

    return (
        <PresenceLoadingScreen isLoading={isLoading}>
            <Box
                flex={1}
                alignItems="center"
            >
                <Box
                    w={{
                        base: '100%',
                        lg: WEB_LG_MAX_WIDTH,
                    }}
                >
                    <CompetitionsList
                        items={sorted}
                        loading={isLoading}
                    />
                </Box>
            </Box>
        </PresenceLoadingScreen>
    );
};

export default CompetitionsMyTournaments;
