import {Box, FlatList} from 'native-base';
import {Competition, CompetitionUiPreviewData} from '@shared/models/competition';
import React, {useCallback} from 'react';
import CompetitionCard from '@ui/features/competition/components/CompetitionCard';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';

export type CompetitionListItem = {
    item: Competition | CompetitionUiPreviewData;
    userJoined: boolean;
}

interface CompetitionListProps {
    items: CompetitionListItem[];
    loading: boolean;
    _onReachEnd?: () => void;
    _renderFooter?: () => JSX.Element;
}

const CompetitionsList = (props: CompetitionListProps) => {
    const {items, loading, _onReachEnd, _renderFooter} = props;

    const _renderItem = useCallback(({item}: {item: CompetitionListItem}) => {
        const {item: competition, userJoined} = item;
        return (
            <Box
                px={{
                    base: '4',
                    lg: '10%',
                }}
            >
                <CompetitionCard competition={competition} userJoined={userJoined} />
            </Box>
        );
    }, []);

    return (
        <PresenceLoadingScreen isLoading={loading}>
            <FlatList
                keyExtractor={item => item.item.id}
                data={items}
                ItemSeparatorComponent={() => <Box h='2' w='2'/>}
                renderItem={_renderItem}
                onEndReachedThreshold={0.2}
                onEndReached={_onReachEnd}
                showsVerticalScrollIndicator={false}
                ListHeaderComponent={() => <Box pt='4'/>}
                ListFooterComponent={_renderFooter}
            />
        </PresenceLoadingScreen>
    );
};

export default CompetitionsList;
